.page-container {
  height: 40vh;
  padding-left: 15%;
  padding-right: 15%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  height: max-content;
  flex-flow: row wrap;
}
.page-section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 130px;
  padding-bottom: 50px;
}
.page-container .page-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 350px;
}
h1 {
  margin-top: 50px;
  margin-bottom: 0px;
  text-align: left;
}
p {
  margin-top: 15px;
  margin-bottom: 5px;
}

.page-content button {
  width: 100px;
  height: 30px;
  border: none;
  border-radius: 5px;
  background-color: black;
  color: white;
  font-size: 16px;
}
.page-content button:hover {
  cursor: pointer;
  transform: scale(1.05);
  box-shadow: 0px 2.3px 5.3px rgb(180, 180, 180);
}
.page-content button:active {
  cursor: pointer;
  transform: scale(1);
  box-shadow: 0px 1px 2.5px rgb(180, 180, 180);
}
.page-content img {
  margin-top: 50px;
  padding: 20px;
  padding-left: 50px;
  height: 400px;
  width: 400px;
}
