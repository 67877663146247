.press-container {
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-flow: row wrap;
  margin-bottom: 20px;
}
.press-container .press-content {
  display: flex;
  flex-wrap: wrap;
  width: 250px;
  align-items: center;
  margin: 15px;
}
.press-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.press-content img {
  width: 250px;
  justify-content: center;
}
@media only screen and (max-width: 800px) {

  .press-container {


  }
  .press-content img {
    flex-direction: column;
  }
}