.info-cards {
  width: 100vw;
  height: 100%;
  justify-content: center;
  display: flex;
  margin-bottom: 40px;
  margin-top: 50px;
}
.info-cards p {
  color: black;
}

.info-cards .card {
  width: 150px;
  height: 120px;
  margin-left: 15px;
  margin-right: 15px;

  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: none;
}

.info-cards .card .card-title {
  margin-top: -50px;
}
.info-cards .card .card-body {
  padding: 15px;
  height: 150px;
}
.info-cards h1 {
  color: #48c2ff;
  display: flex;
  padding: 15px;
  justify-content: left;
  margin-bottom: -50px;
  height: 50px;
  width: 100%;
}
.card .card-logo {
  display: flex;
  justify-content: center;
  height: 50px;
  height: 100px;
  width: 100%;

  position: relative;
  background-color: white;
}
.card .card-logo:hover {
  opacity: 1;
}
.card-logo img {
  width: 30px;
}
@media only screen and (max-width: 800px) {
  .info-cards {
    display: grid;
  }
  .info-cards .card {
    width: 350px;
  }
}
