.page {
  height: 100vh;

}
.page .artists {
  display: flex;
  margin-top: 100px;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  height: max-content;
  flex-flow: row wrap;
}