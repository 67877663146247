/* This is the styling for the nav container */
.navbar {
  width: 100vw;
  height: 90px;
  background-color: black;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
}
/* This is the styling for the nav content */

.navbar-content {
  padding-top: 15px;
  width: 100%;
  height: 80px;
  align-items: center;
  display: flex;
}

/* This is the styling for the left nav container */

.left-nav {
  color: #000;
  flex: 30%;
  display: flex;
  align-items: center;
  padding-left: 5%;
}

.left-nav img {
  width: 120px;
  padding-bottom: 15px;
}
/* This is the styling for the right nav container */

.right-nav {
  flex: 70%;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  padding-bottom: 15px;
}

a {
  color: rgb(245, 245, 245);
  font-size: large;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  margin: 15px;
}

a:hover {
  color: #fff;
}

.navbar-content button {
  width: 150px;
  height: 50px;
  border: none;
  margin-left: 15px;
  align-items: left;
  background-color: white;
  color: black;
  font-size: 16px;
  transition: .6s;
  font-weight:900;
}


.navbar-content button:hover {
  cursor: pointer;
  transform: scale(1.02);
  background-color: #48c2ff;
  color: white;
}
.navbar-content button:active {
  cursor: pointer;
  transform: scale(1);

}
