.featured-container {

  padding-bottom: 50px;
  padding-top: 50px;
  background-color: black;
  height: 200px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  height: max-content;
  flex-flow: row wrap;
}
.featured-container .featured-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  border-radius: 0;
  width: 450px;
  align-items: left;
  margin: 15px;
  padding: 15px;
  transition: 0.8s;
}
.featured-content:hover {
  background-color: rgb(32, 32, 32);
}
.featured-content h1 {
  margin-top: 0px;
  color: white;
  text-align: left;
  font-size: 32px;
}
.featured-content p {
  color: white;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 0px;
}
.featured-content button {
  width: 100px;
  height: 30px;
  margin-top: 15px;
  border: none;
  border-radius: 5px;
  background-color: white;
  color: black;
  font-size: 16px;
}
.featured-content button:hover {
  cursor: pointer;
  transform: scale(1.05);
  box-shadow: 0px 2.3px 5.3px rgb(0, 0, 0);
}
.featured-content button:active {
  cursor: pointer;
  transform: scale(1);
  box-shadow: 0px 1px 2.5px rgb(0, 0, 0);
}
@media only screen and (max-width: 800px) {
  .featured-container .featured-content {
    width: 80vw;
  }

}
