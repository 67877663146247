.card {
  height: 250px;
  width: 750px;
  min-width: 250px;
  background-color: #161616;
  margin: 10px;
}

.card-content {
  padding: 15px;
}

.card-img {
  height: 80%;
  width: 80%;
}
