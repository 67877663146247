.info-cards {
  width: 100vw;
  height: 100%;
  justify-content: center;
  display: flex;
  margin-bottom: 50px;
}
.info-cards p {
  color: black;
}
.info-cards .card {
  width: 150px;
  height: 250px;
  overflow: hidden;
  position: relative;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  background-color: white;
  transition: .6s;
  cursor: pointer;
}
.info-cards .card:hover {
transform: scale(1.02);
box-shadow: 0px 10px 30px rgba(158, 158, 158, 0.349);
}
.info-cards .card:active {
  transform: scale(1);
  box-shadow: 0px 10px 20px rgba(158, 158, 158, 0.349);
}

.info-cards .card .card-title {
  margin-top: -35px;
}
.info-cards .card .card-body {
  padding: 15px;
  height: 150px;
}
.info-cards h1 {
  color: #48c2ff;
  display: flex;
  justify-content: center;
  height: 50px;
  width: 100%;
}
.card .card-logo {
  display: flex;
  justify-content: center;
  height: 50px;
  width: 100%;
  position: relative;
  background-color: #48c2ff;
}
.card .card-logo:hover {
  opacity: 1;
}
.card-logo img {
  width: 30px;
}
@media only screen and (max-width: 1000px) {
  .info-cards {
    display: grid;
  }
  .info-cards .card {
    width: 500px;
  }
}
