@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;900&display=swap');
.App {
  text-align: center;
}
body {
  padding: 0px;
  margin: 0px;
  background-color: white;
}
h1, p {
  font-family: 'Roboto', sans-serif;
}
.unity-canvas {
  width: 100%;
  height: 100vh;
}