.header-container {
  height: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: left;
  flex-flow: row wrap;
  justify-content: center;
}
.header-container .header-content {
  display: flex;
  width: 400px;
  flex-wrap: wrap;
  flex-direction: column;
}
.header-content h1 {
  margin-top: 120px;
  text-align: left;
  font-size: 38px;
}
.header-content p {
  margin-top: 15px;
  font-size: 20px;
  color: rgb(180, 180, 180);
}
.header-content button {
  width: 150px;
  height: 50px;
  border: none;
  margin-left: -15px;
  margin-top: 15px;
  align-items: left;
  font-weight: lighter;
  background-color: black;
  color: white;
  font-size: 18px;
  transition: 0.6s;
  cursor: pointer;
  font-weight: 900;
}
.header-content a {
  color: white;
}

.header-content button:hover {
  cursor: pointer;
  transform: scale(1.05);
  background-color: #48c2ff;
  box-shadow: 0px 2.3px 5.3px rgb(180, 180, 180);
}
.header-content button:active {
  cursor: pointer;
  transform: scale(1);
  background-color: #48c2ff;
  box-shadow: 0px 1px 2.5px rgb(180, 180, 180);
}
.header-content img {
  margin-top: 50px;
  margin-left: -100px;
  height: 500px;
  width: 500px;
}

.network-img img {
  margin-top: 35px;
  height: 350px;
  width: 350px;
}

@media only screen and (max-width: 800px) {
  .header-container {
    height: 85vh;
  }
  .header-content h1 {
    font-size: 32px;
    font-weight: bold;
  }
  .header-content p {
    font-size: 18px;
  }
  .header-content img {
    margin: auto;
    height: 350px;
    width: 350px;
    margin-top: -80px;
  }
  .network-img img {
    height: 350px;
    width: 350px;
  }
}
