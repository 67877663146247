.ad-section {
  width: 100vw;
  height: 250px;
  margin: 0px auto;
  background-color: #48c2ff;
  background-image: url("");
  background-position: center;
  background-size: cover;
  align-items: center;
  justify-content: center;
  padding-top: 10vh;
  padding-bottom: 10vh;
}
.ad-section h1 {
  padding-bottom: 25px;
}
.ad-section p {
  color: white;
  font-size: 18px;
  text-align: center;
}
.ad-card {
  margin: 0 auto;
  margin-top: 20px;
  background-color: white;
  width: 350px;
  height: 180px;
  text-align: center;
  position: center;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.3s ease;
}
.ad-card:hover {
  transform: scale(1.01);
  box-shadow: 0px 10.1px 20.1px rgba(0, 0, 0, 0.1);
}
.ad-card:active {
  transform: scale(1);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}
.ad-card h2 {
  color: black;
  font-weight: lighter;
  padding-top: 10px;
  font-size: 18px;
}
.ad-card h1 {
  font-size: 72px;
  padding-bottom: 10px;
  color: #48c2ff;
}
.ad-card img {
  height: 80px;
  margin-top: 30px;
  padding-bottom: 10px;
}
.bottom-card {
  padding-top: 15px;
 
  border-radius: 0px 0px 15px 15px;
}
.ad-card button {
  outline: none;
  border: none;
  padding-top: 70px;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0);
  color: #48c2ff;
  font-size: 32px;
  font-weight: bold;
  cursor: pointer;
}

